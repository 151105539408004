import styled from 'styled-components'
import { SELECTED_CHAIN_ID } from '../../apollo/factory'
import {
  ChainId,
  CHAIN_IDS_TO_LABLES,
  CHAIN_IDS_TO_LABLES_SHORT,
  CHAIN_IDS_TO_NAMES,
  CHAIN_LOGO,
  SupportedChainId,
} from '../../constants/chainInfo'
import React, { useState } from 'react'

const NetworkBtn = styled.div`
  //border: 1px solid #777;
  padding: 1px 5px;
  border-radius: 50px;
  background: ${({ theme }) => theme.primary1};
  color: white;
  max-height: 16px;
  overflow: hidden;
  width: max-content;
`
const SelectNetwork = styled.div`
  position: absolute;
  z-index: 99999;
  ${'' /* border: 1px solid ${({ theme }) => theme.shadow7}; */}
  box-shadow: 0px 0px 10px ${({ theme }) => theme.shadow7};
  border-radius: 15px;
  width: auto;
  overflow: hidden;
`
const NetworkList = styled.div`
  width: fit-content;
  overflow: hidden;
`
const Network = styled.div`
  padding: 15px 20px;
  display: flex;
  align-items: center;
  transition: all 0.2s;
  background: ${({ theme, active }) => (active ? theme.primary1 : theme.bg7)};
  color: ${({ theme, active }) => (active ? 'white' : theme.text7)};

  :hover {
    background: ${({ theme }) => theme.primary1};
    box-shadow: 0px 0px 5px ${({ theme }) => theme.primary1};

    ${'' /* color: ${({ theme }) => theme.text8}; */}
  }
`
const NetworkLogo = styled.div`
  margin: 5px 10px 5px 0px;
  min-width: 20px;
  width: 20px;
  height: 20px;
  overflow: hidden;
`

export default function NetworkSelector() {
  const [isSelectNetwork, setSelectNetwork] = useState(false)
  const uri = window.location.href.split('?')[0]
  return (
    <>
      <NetworkBtn onMouseEnter={() => setSelectNetwork(true)} onMouseLeave={() => setSelectNetwork(false)}>
        {CHAIN_IDS_TO_LABLES[SELECTED_CHAIN_ID].length > 8
          ? CHAIN_IDS_TO_LABLES_SHORT[SELECTED_CHAIN_ID]
          : CHAIN_IDS_TO_LABLES[SELECTED_CHAIN_ID]}
        {isSelectNetwork && (
          <SelectNetwork>
            <NetworkList>
              {SupportedChainId.map((e) => (
                <Network
                  active={e == SELECTED_CHAIN_ID}
                  key={e}
                  onClick={() => {
                    window.open(uri + '?chainId=' + e, '_self')
                  }}
                >
                  <NetworkLogo>
                    <img src={CHAIN_LOGO[e]} style={{ height: '20px', width: '20px' }} />
                  </NetworkLogo>
                  {CHAIN_IDS_TO_LABLES[e]}
                </Network>
              ))}
            </NetworkList>
          </SelectNetwork>
        )}
      </NetworkBtn>
    </>
  )
}
