import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Flex } from 'rebass'
import Link from '../Link'
import { RowFixed } from '../Row'
import Logo from '../../assets/mama-logo-white.svg'

import { BasicLink } from '../Link'
import { useMedia } from 'react-use'
import NetworkSelector from '../NetworkSelector'

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 10;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

const UniIcon = styled(Link)`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const WhiteLogo = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  margin: 5px 0 5px 8px;
  letter-spacing: 4px;
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.white};
  display: flex;
  margin-left: 12px;
  :hover {
    opacity: 1;
  }
`

export default function Title() {
  const location = useLocation()
  const below1080 = useMedia('(max-width: 1080px)')
  const navigate = useNavigate()

  return (
    <TitleWrapper>
      <Flex alignItems="center" style={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RowFixed onClick={() => navigate('/')}>
            <UniIcon id="link">
              <img width={'24px'} src={Logo} alt="logo" />
            </UniIcon>
            {!below1080 && (
              <WhiteLogo>
                MAMA
                <sup style={{ fontSize: '0.8rem' }} title={'MAMA Alpha v.0.1.1'}>
                  α
                </sup>
              </WhiteLogo>
            )}
          </RowFixed>
          <NetworkSelector />
        </div>
        {below1080 && (
          <RowFixed style={{ alignItems: 'flex-end' }}>
            <BasicLink to="/home">
              <Option activeText={location.pathname === '/home' ?? undefined}>Overview</Option>
            </BasicLink>
            <BasicLink to="/tokens">
              <Option
                activeText={
                  (location.pathname.split('/')[1] === 'tokens' || location.pathname.split('/')[1] === 'token') ??
                  undefined
                }
              >
                Tokens
              </Option>
            </BasicLink>
            <BasicLink to="/pairs">
              <Option
                activeText={
                  (location.pathname.split('/')[1] === 'pairs' || location.pathname.split('/')[1] === 'pair') ??
                  undefined
                }
              >
                Pairs
              </Option>
            </BasicLink>

            <BasicLink to="/accounts">
              <Option
                activeText={
                  (location.pathname.split('/')[1] === 'accounts' || location.pathname.split('/')[1] === 'account') ??
                  undefined
                }
              >
                Accounts
              </Option>
            </BasicLink>
          </RowFixed>
        )}
      </Flex>
    </TitleWrapper>
  )
}
