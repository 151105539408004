import { ChainId } from '../constants/chainInfo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export let SELECTED_CHAIN_ID = 56
// Get ChainId from URL query
function setSelectedChainId(chainId?: ChainId) {
  try {
    // check in case user refreshed page
    if (chainId == null && localStorage.selected_chain_id) chainId = Number(localStorage.selected_chain_id) as ChainId
    // if user change chain, chainId will be in URL
    const _chainId = window.location.search
      .split('&')
      ?.find((e) => e.includes('chainId'))
      ?.split('=')[1]
    // for dev purposes
    if (_chainId) chainId = Number(_chainId) as ChainId
    if (chainId == undefined) chainId = ChainId.BINANCE

    SELECTED_CHAIN_ID = chainId
    localStorage.selected_chain_id = SELECTED_CHAIN_ID
    return true
  } catch (error) {
    return false
  }
}

export const ClientFactory = (chainId: ChainId) => {
  if (!setSelectedChainId(chainId)) throw new Error('Cannot identify chain by chainId, and create ApolloClient')
  let _uri = ''
  switch (SELECTED_CHAIN_ID) {
    case ChainId.GOERLI:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-swap-goerli'
      break
    case ChainId.MAINNET:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-swap-mainnet'
      break
    case ChainId.BINANCE_TESTNET:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-swap-bsc-testnet'
      break
    case ChainId.BINANCE:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-swap-bsc'
      break
    default:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-swap-mainnet'
  }

  return new ApolloClient({
    link: new HttpLink({
      uri: _uri,
    }),
    cache: new InMemoryCache(),
  })
}

// export const HealthClientFactory = (chainId: ChainId) => {
//   if (!setSelectedChainId(chainId)) throw new Error('Cannot identify chain by chainId, and create ApolloClient')

//   let _uri = ''
//   switch (SELECTED_CHAIN_ID) {
//     case ChainId.GOERLI:
//       _uri = 'https://api.thegraph.com/index-node/graphql'
//       break
//     case ChainId.MAINNET:
//       _uri = 'https://api.thegraph.com/index-node/graphql'
//       break
//     case ChainId.BINANCE_TESTNET:
//       _uri = 'https://api.thegraph.com/index-node/graphql'
//       break
//     case ChainId.BINANCE:
//       _uri = 'https://api.thegraph.com/index-node/graphql'
//       break
//     default:
//       _uri = 'https://api.thegraph.com/index-node/graphql'
//   }

//   return new ApolloClient({
//     link: new HttpLink({
//       uri: _uri,
//     }),
//     cache: new InMemoryCache(),
//   })
// }

export const V1ClientFactory = (chainId: ChainId) => {
  if (!setSelectedChainId(chainId)) throw new Error('Cannot identify chain by chainId, and create ApolloClient')
  let _uri = ''
  switch (SELECTED_CHAIN_ID) {
    case ChainId.GOERLI:
      _uri = 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap'
      break
    default:
      _uri = 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap'
  }

  return new ApolloClient({
    link: new HttpLink({
      uri: _uri,
    }),
    cache: new InMemoryCache(),
  })
}

export const StakingClientFactory = (chainId: ChainId) => {
  if (!setSelectedChainId(chainId)) throw new Error('Cannot identify chain by chainId, and create ApolloClient')
  let _uri = ''
  switch (SELECTED_CHAIN_ID) {
    case ChainId.GOERLI:
      _uri = 'https://api.thegraph.com/subgraphs/name/way2rach/talisman'
      break
    case ChainId.MAINNET:
      _uri = 'https://api.thegraph.com/subgraphs/name/way2rach/talisman'
      break
    case ChainId.BINANCE_TESTNET:
      _uri = 'https://api.thegraph.com/subgraphs/name/way2rach/talisman'
      break
    case ChainId.BINANCE:
      _uri = 'https://api.thegraph.com/subgraphs/name/way2rach/talisman'
      break
    default:
      _uri = 'https://api.thegraph.com/subgraphs/name/way2rach/talisman'
  }

  return new ApolloClient({
    link: new HttpLink({
      uri: _uri,
    }),
    cache: new InMemoryCache(),
  })
}

export const BlockClientFactory = (chainId: ChainId) => {
  if (!setSelectedChainId(chainId)) throw new Error('Cannot identify chain by chainId, and create ApolloClient')
  let _uri = ''
  switch (SELECTED_CHAIN_ID) {
    case ChainId.GOERLI:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-blocks-goerli'
      break
    case ChainId.MAINNET:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-blocks-mainnet'
      break
    case ChainId.BINANCE_TESTNET:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-blocks-bsc-testnet'
      break
    case ChainId.BINANCE:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-blocks-bsc'
      break
    default:
      _uri = 'https://api.thegraph.com/subgraphs/name/aryzeofficial/aryze-blocks-mainnet'
  }

  return new ApolloClient({
    link: new HttpLink({
      uri: _uri,
    }),
    cache: new InMemoryCache(),
  })
}
