import eth_logo from '../assets/chains/ethereum-logo.png'
import bsc_logo from '../assets/chains/binance-logo.svg'
import celo_logo from '../assets/chains/celo-logo.svg'
import polygon_logo from '../assets/chains/polygon-matic-logo.svg'
import arbitrum_logo from '../assets/chains/arbitrum-logo.svg'
import optimism_logo from '../assets/chains/optimism-logo.svg'
let currentChainId = 5
export const CURRENT_CHAIN_ID = (_chainId): ChainId => {
  if (_chainId) currentChainId = _chainId
  return currentChainId
}

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  SEPOLIA = 11155111,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,

  OPTIMISM = 10,
  OPTIMISTIC_KOVAN = 69,

  POLYGON = 137,
  POLYGON_MUMBAI = 80001,

  CELO = 42220,
  CELO_ALFAJORES = 44787,

  BINANCE = 56,
  BINANCE_TESTNET = 97,
}
export const SupportedChainId = [
  ChainId.BINANCE,
  // ChainId.BINANCE_TESTNET,
  // ChainId.MAINNET,
  // ChainId.GOERLI,
  // ChainId.SEPOLIA,
]

export const CHAIN_IDS_TO_NAMES = {
  [ChainId.MAINNET]: 'mainnet',
  [ChainId.ROPSTEN]: 'ropsten',
  [ChainId.RINKEBY]: 'rinkeby',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.KOVAN]: 'kovan',
  [ChainId.SEPOLIA]: 'sepolia',
  [ChainId.POLYGON]: 'polygon',
  [ChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [ChainId.CELO]: 'celo',
  [ChainId.CELO_ALFAJORES]: 'celo_alfajores',
  [ChainId.ARBITRUM_ONE]: 'arbitrum',
  [ChainId.ARBITRUM_RINKEBY]: 'arbitrum_rinkeby',
  [ChainId.OPTIMISM]: 'optimism',
  [ChainId.OPTIMISTIC_KOVAN]: 'optimistic_kovan',
  [ChainId.BINANCE]: 'binance',
  [ChainId.BINANCE_TESTNET]: 'binance_testnet',
}

export const CHAIN_IDS_TO_LABLES = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.GOERLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.SEPOLIA]: 'Sepolia',
  [ChainId.POLYGON]: 'Polygon',
  [ChainId.POLYGON_MUMBAI]: 'Mumbai',
  [ChainId.CELO]: 'Celo',
  [ChainId.CELO_ALFAJORES]: 'Celo Alfajores',
  [ChainId.ARBITRUM_ONE]: 'Arbitrum',
  [ChainId.ARBITRUM_RINKEBY]: 'Arbitrum Rinkeby',
  [ChainId.OPTIMISM]: 'Optimism',
  [ChainId.OPTIMISTIC_KOVAN]: 'Optimistic Kovan',
  [ChainId.BINANCE]: 'Binance',
  [ChainId.BINANCE_TESTNET]: 'Binance Testnet',
}

export const CHAIN_IDS_TO_LABLES_SHORT = {
  [ChainId.MAINNET]: 'ETH',
  [ChainId.ROPSTEN]: 'ROP',
  [ChainId.RINKEBY]: 'RIN',
  [ChainId.GOERLI]: 'GÖR',
  [ChainId.KOVAN]: 'KOV',
  [ChainId.SEPOLIA]: 'SEP',
  [ChainId.POLYGON]: 'POL',
  [ChainId.POLYGON_MUMBAI]: 'MUM',
  [ChainId.CELO]: 'CELO',
  [ChainId.CELO_ALFAJORES]: 'ALFA',
  [ChainId.ARBITRUM_ONE]: 'ARB',
  [ChainId.ARBITRUM_RINKEBY]: 'ARB RIN',
  [ChainId.OPTIMISM]: 'OP',
  [ChainId.OPTIMISTIC_KOVAN]: 'OP KOV',
  [ChainId.BINANCE]: 'BSC',
  [ChainId.BINANCE_TESTNET]: 'BSC TEST',
}

export const CHAIN_EXPLORER_URL = {
  [ChainId.MAINNET]: 'https://etherscan.io/',
  [ChainId.ROPSTEN]: 'https://ropsten.etherscan.io/',
  [ChainId.RINKEBY]: 'https://rinkeby.etherscan.io/',
  [ChainId.GOERLI]: 'https://goerli.etherscan.io/',
  [ChainId.POLYGON]: 'https://polygonscan.com/',
  [ChainId.POLYGON_MUMBAI]: 'https://mumbai.polygonscan.com/',
  [ChainId.CELO]: 'https://celoscan.io/',
  [ChainId.CELO_ALFAJORES]: 'https://alfajores.celoscan.io/',
  [ChainId.ARBITRUM_ONE]: 'https://arbiscan.io/',
  [ChainId.ARBITRUM_RINKEBY]: 'https://testnet.arbiscan.io/',
  [ChainId.OPTIMISM]: 'https://optimistic.etherscan.io/',
  [ChainId.OPTIMISTIC_KOVAN]: 'https://kovan-optimistic.etherscan.io/',
  [ChainId.BINANCE]: 'https://bscscan.com/',
  [ChainId.BINANCE_TESTNET]: 'https://testnet.bscscan.com/',
}

export const CHAIN_IDS_TO_NATIVE_TOKEN = {
  [ChainId.MAINNET]: 'ETH',
  [ChainId.ROPSTEN]: 'ETH',
  [ChainId.RINKEBY]: 'ETH',
  [ChainId.GOERLI]: 'ETH',
  [ChainId.KOVAN]: 'ETH',
  [ChainId.SEPOLIA]: 'ETH',
  [ChainId.POLYGON]: 'MATIC',
  [ChainId.POLYGON_MUMBAI]: 'MATIC',
  [ChainId.CELO]: 'CELO',
  [ChainId.CELO_ALFAJORES]: 'CELO',
  [ChainId.ARBITRUM_ONE]: 'ETH',
  [ChainId.ARBITRUM_RINKEBY]: 'ETH',
  [ChainId.OPTIMISM]: 'ETH',
  [ChainId.OPTIMISTIC_KOVAN]: 'ETH',
  [ChainId.BINANCE]: 'BNB',
  [ChainId.BINANCE_TESTNET]: 'BNB',
}

export const CHAIN_LOGO = {
  [ChainId.MAINNET]: eth_logo,
  [ChainId.ROPSTEN]: eth_logo,
  [ChainId.RINKEBY]: eth_logo,
  [ChainId.GOERLI]: eth_logo,
  [ChainId.POLYGON]: polygon_logo,
  [ChainId.POLYGON_MUMBAI]: polygon_logo,
  [ChainId.CELO]: celo_logo,
  [ChainId.CELO_ALFAJORES]: celo_logo,
  [ChainId.ARBITRUM_ONE]: arbitrum_logo,
  [ChainId.ARBITRUM_RINKEBY]: arbitrum_logo,
  [ChainId.OPTIMISM]: optimism_logo,
  [ChainId.OPTIMISTIC_KOVAN]: optimism_logo,
  [ChainId.BINANCE]: bsc_logo,
  [ChainId.BINANCE_TESTNET]: bsc_logo,
}

export const CHAIN_START_TIMESTAMP = {
  [ChainId.MAINNET]: 1663317059,
  [ChainId.ROPSTEN]: 0,
  [ChainId.RINKEBY]: 0,
  [ChainId.GOERLI]: 1663316676,
  [ChainId.POLYGON]: 0,
  [ChainId.POLYGON_MUMBAI]: 0,
  [ChainId.CELO]: 0,
  [ChainId.CELO_ALFAJORES]: 0,
  [ChainId.ARBITRUM_ONE]: 0,
  [ChainId.ARBITRUM_RINKEBY]: 0,
  [ChainId.OPTIMISM]: 0,
  [ChainId.OPTIMISTIC_KOVAN]: 0,
  [ChainId.BINANCE]: 1663316294,
  [ChainId.BINANCE_TESTNET]: 1663316133,
}
export const CHAIN_FACTORY_BLOCK = {
  [ChainId.MAINNET]: 15545893,
  [ChainId.ROPSTEN]: 0,
  [ChainId.RINKEBY]: 0,
  [ChainId.GOERLI]: 7602799,
  [ChainId.POLYGON]: 0,
  [ChainId.POLYGON_MUMBAI]: 0,
  [ChainId.CELO]: 0,
  [ChainId.CELO_ALFAJORES]: 0,
  [ChainId.ARBITRUM_ONE]: 0,
  [ChainId.ARBITRUM_RINKEBY]: 0,
  [ChainId.OPTIMISM]: 0,
  [ChainId.OPTIMISTIC_KOVAN]: 0,
  [ChainId.BINANCE]: 21381085,
  [ChainId.BINANCE_TESTNET]: 22889316,
}

export const CHAIN_RPC_URLS = {
  [ChainId.MAINNET]: [
    'https://mainnet.infura.io/v3/7c14bd275dfb4468b20d9440443bae7f',
    'https://eth-mainnet-public.unifra.io',
  ],
  [ChainId.ROPSTEN]: [],
  [ChainId.RINKEBY]: [],
  [ChainId.GOERLI]: [
    'https://goerli.infura.io/v3/7c14bd275dfb4468b20d9440443bae7f',
    'https://eth-goerli.public.blastapi.io',
  ],
  [ChainId.POLYGON]: [],
  [ChainId.POLYGON_MUMBAI]: [],
  [ChainId.CELO]: [],
  [ChainId.CELO_ALFAJORES]: [],
  [ChainId.ARBITRUM_ONE]: [],
  [ChainId.ARBITRUM_RINKEBY]: [],
  [ChainId.OPTIMISM]: [],
  [ChainId.OPTIMISTIC_KOVAN]: [],
  [ChainId.BINANCE]: [
    'https://bsc-mainnet.blastapi.io/7533d2c1-cb3e-44fc-868a-6f21f636ca5e',
    'https://bsc-dataseed4.binance.org',
  ],
  [ChainId.BINANCE_TESTNET]: [
    'https://bsc-testnet.blastapi.io/7533d2c1-cb3e-44fc-868a-6f21f636ca5e',
    'https://data-seed-prebsc-1-s3.binance.org:8545',
  ],
}
