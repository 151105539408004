import {
  BlockClientFactory,
  ClientFactory,
  // HealthClientFactory,
  StakingClientFactory,
  V1ClientFactory,
} from './factory'
import { ChainId } from '../constants/chainInfo'

export const client = ClientFactory()
// export const healthClient = HealthClientFactory()
export const v1Client = V1ClientFactory()
export const stakingClient = StakingClientFactory()
export const blockClient = BlockClientFactory()
